@import '~@carbon/themes/scss/themes';

$carbon--theme: $carbon--theme--g100;
@include carbon--theme($carbon--theme--g100);


body, html, main, main.bx--content, .bx--content {
  background-color: $ui-background;
  color: $text-01;
}

.xfr-toaster {
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  z-index: 10;
  max-height: 100%;
}