@import '~@carbon/themes/scss/themes';

$carbon--theme: $carbon--theme--g100;
@include carbon--theme($carbon--theme--g100);


.xfr-view-repo {
  .bx--toolbar-content {
    &:hover, &:active, &:focus {
      z-index: 2;
    }
  }

  .bx--batch-actions--active {
    z-index: 1;
  }


  .xf-files-header {
    display: flex;
    justify-content: space-between;
    background: $ui-01;

    .bx--data-table-header {
      padding: 1rem 1rem 1.5rem 1rem;
    }

    .xf-title-wrap {
      align-self: flex-start;
      display: flex;
      align-content: flex-start;
      justify-items: flex-start;

      .xf-own-actions {
        margin-left: .1rem;
      }
    }

    .xf-add-files {
      align-self: flex-end;
      display: flex;
      flex: 1 1 auto;
      justify-content: flex-end;

      .xf-added-files {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        flex-wrap: wrap-reverse;
        align-content: flex-start;
        justify-content: flex-start;
        max-height: 8rem;
        padding-top: 0.75rem;
        overflow: auto;
        scrollbar-width: thin;

        .bx--file__selected-file {
          border: 1px solid $ui-02;
          margin: 0.25rem;
        }
      }

      .bx--file {
        z-index: 1;
        margin: 1rem 1rem -1.4rem;
        width: auto;

        .bx--file-browse-btn {
          margin: 0;
        }

        .bx--file__drop-container {
          background: $ui-01;
        }

        &:hover {
          z-index: 2;
        }
      }
    }
  }

  .xf-file-actions {
    display: flex;
  }
}

// modal is in root so these need to be out too
.xf-edit-file {
  margin-bottom: 0;
  padding-bottom: 1rem;

  .xf-perms .perm-entry {
    .xf-btn-del {
      margin-left: 32px;
    }

    &:first-child {
      .xf-btn-del {
        margin-top: 24px;
      }
    }
  }

  .xf-edit-perms {
    h4 {
      margin-top: 1rem;
    }

    .xf-perm-add {
      margin-top: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-content: space-between;
    }
  }

  .perm-entry {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0;
    padding: 0.5rem 0 0;

    .xf-btn-add, .xf-btn-del {
      flex: 0 0 16px;
      justify-self: center;
      align-self: center;
    }

    .bx--toggle-input__label .bx--toggle__switch {
      margin-top: 1.3rem;
    }

    + .perm-entry {
      padding-top: 1rem;

      .bx--label {
        display: none;
      }
      .bx--toggle-input__label {
        visibility: hidden;
        font-size: 0;
        line-height: 0;

        .bx--toggle__switch {
          visibility: visible;
          margin-top: 0.8rem;
        }
      }
    }

    .perm-eml {
      margin-right: 1rem;

      input {
        min-width: 24rem;
      }
    }

    .xf-tog {
      flex: 1 1 90px;
    }
  }

  .xf-btn-flex {
    display: flex;
    justify-content: space-between;
  }
}
