@import '@carbon/themes/scss/themes';

$carbon--theme: $carbon--theme--g100;
@include carbon--theme($carbon--theme--g100);


.xfr-add-repo {
  .add-repo-form {
    padding: 2rem;
    border: 1px dashed $ui-01;
    border-top: none;
    background: $ui-02;

    .xf-perms .perm-entry {
      .xf-btn-del {
        margin-left: 32px;
      }

      &:first-child {
        .xf-btn-del {
          margin-top: 24px;
        }
      }
    }

    .perm-entry {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 0;
      padding: 2rem 0 0;

      .xf-btn-add, .xf-btn-del {
        flex: 0 0 16px;
        justify-self: center;
        align-self: center;
      }

      .bx--toggle-input__label .bx--toggle__switch {
        margin-top: 1.3rem;
      }

      + .perm-entry {
        padding-top: 1rem;

        .bx--label {
          display: none;
        }
        .bx--toggle-input__label {
          visibility: hidden;
          font-size: 0;
          line-height: 0;

          .bx--toggle__switch {
            visibility: visible;
            margin-top: 0.8rem;
          }
        }
      }

      .perm-eml {
        margin-right: 1rem;

        input {
          min-width: 24rem;
        }
      }

      .xf-tog {
        flex: 1 1 100px;
      }
    }

    .xf-btn-flex {
      display: flex;
      justify-content: space-between;
    }
  }
}