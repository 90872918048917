@import '@carbon/themes/scss/themes';


.xfr-main-header {

  .xfr-progress {
    content: ' ';
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: -1;
    border: 1px solid #8c101c;  // red-70
    border-radius: 1px;
  }

  .header-xfr-icon {
    fill: #f4f4f4;
    margin-left: 1rem;
    margin-right: -0.5rem;
  }

  .xfr-profile-pic {
    display: inline-flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    color: #f4f4f4;

    img {
      max-width: 32px;
      border-radius: 50%;
    }
  }

  .xfr-side-panel {
    display: flex;
    flex-direction: column;

    > div {
      width: 254px;
    }

    .xfr-hp-inner {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .xfr-switcher {
        margin-top: 1rem;
      }
    }

    .xfr-user-info {
      display: flex;
      flex-direction: column;
      font-size: 0.875rem;
      line-height: 1.125rem;
      letter-spacing: 0.16px;
      text-decoration: none;
      color: #c6c6c6;

      .xui-head {
        font-weight: 600;
        padding: 0.375rem 1rem;
      }

      .xui-sub {
        padding: 0.1rem 1.5rem;
      }
    }
  }

  .bx--header__name--prefix {
    margin-right: 0;
  }

  .xfr-divider {
    margin-right: 3px;
    font-weight: 400;
  }

  .header-xfr-icon {
    fill: $icon-01;
    margin-left: 1rem;
    margin-right: -0.5rem;
  }

  .sirius-star {
    margin-top: -13px;
    margin-left: -2px;
    font-weight: lighter;
    font-size: 1rem;
  }
}